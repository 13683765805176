@import '@styles/styles.scss';

.ItemPayMethodOption {
    background: $primaryGradient;
    color: $primaryTextContrast;
    width: 183px;
    height: 64px;

    .payMethodName {
        color: $primaryTextContrast;
    }
}
