@import '@styles/styles.scss';

.pageWrapper {
    top: 0;
    height: 25vh;
    width: 100%;
    background-image: url('../../assets/images/stepper/stepperBk.png');
    z-index: -1;

    @include tablet {
        height: 40vh;
    }
}
.wrapper {
    max-width: 1200px;
    margin: 0 auto;
}

.mainInfosWrapper {
    padding-top: 20px;

    .infosTitle {
        font-size: $FONTSIZE_20;
        color: $primaryColor;
    }

    @include tablet {
        flex-direction: column;
    }
}

/*=============================================
=                 Buttons                     =
=============================================*/
.btnGroupWrapper {
    display: flex;
    justify-content: center;
    margin-top: 0;
    flex-direction: row-reverse;

    &:after {
        content: none;
    }

    @include desktop {
        padding: 20px 0px 20px;
    }

    @include mobile {
        padding: 20px 10px;
    }
}

.btn {
    .content {
        .iconAddition {
            display: none;
        }
    }

    & + .btn {
        margin-left: 0;
        margin-right: 20px;
    }

    @include mobileSmall {
        text-align: center;
    }

    &.MuiButton-root,
    &.MuiButton-contained,
    &.MuiButton-outlined {
        border-radius: 0;
        min-width: 30%;
    }

    &.MuiButton-contained {
        svg .fillArea {
            fill: $primaryTextContrast;
            stroke: $primaryTextContrast;
        }

        &.Mui-disabled {
            background-color: $primaryColor;
            color: rgba($primaryTextContrast, 0.5);
            opacity: 0.75;

            .MuiButton-label {
                color: $primaryTextContrast;
            }
        }
    }

    &.MuiButton-outlined {
        color: $primaryColor;
        background-color: transparent;
        box-shadow: none;
        border: 1px solid $primaryColor;

        &:hover {
            box-shadow: none;
            text-decoration: none;
            background-color: rgba(0, 0, 0, 0.04);
        }

        .MuiButton-label {
            color: $COLOR_STEELGREY;
        }
    }
}

/*=====  End of Buttons  ======*/
.MuiOutlinedInput-root.inputField,
.react-tel-input .inputField {
    border-radius: 0;
}

.timer p {
    background: $primaryGradient;
    border-radius: 0;
}
.itemField {
    &.unitCount,
    &.realAmount {
        .unit,
        .numeric {
            color: $primaryColor;
            line-height: 1;
        }
    }
}

.snackbarWrapper {
    &.MuiAlert-standardSuccess .MuiAlert-icon,
    &.MuiAlert-standardSuccess {
        background: $primaryGradient;
        color: $primaryTextContrast;
    }
}

.amountInfosWrapper,
.recipientInfos {
    .itemFieldList {
        display: flex;
        flex-wrap: wrap;
        .itemField {
            min-width: 50%;
        }

        @include tablet {
            flex-direction: column;
            .itemField {
                width: auto;
            }
        }
    }
}
