@import '@styles/styles.scss';

.itemTag {
    button {
        background: $primaryGradient;
        border-radius: 32px;

        .iconTick {
            width: 15px;
        }
    }
}
