@import '@styles/styles.scss';

.confirmInfos {
    display: flex;
    justify-content: center;
    background-color: transparent;

    @include mobile {
        justify-content: flex-start;
    }

    .infosTitle {
        display: none;
    }

    @include tablet {
        width: 100%;
    }
}
