@import '@styles/variables.scss';

/*=============================================
=               Template Color                =
=============================================*/
$primaryColor: $COLOR_MIDNIGHT_BLUE;
$infosBkColor: $COLOR_WHITE;
$primaryTextContrast: $COLOR_WHITE;
$bodyBkColor: #f9f9f9;

$primaryGradient: linear-gradient(232.2deg, #0262e9 0%, #0054ae 100%);
/*=====  End of Template Colors  ======*/
