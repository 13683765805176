@import '@styles/styles.scss';

.stepper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    color: $primaryTextContrast;
    padding: 20px 30px;
    margin: 20px 30px;
    border-radius: 12px;
    text-align: end;

    position: relative;

    .stepIndex {
        font-size: $FONTSIZE_20;

        .currStepIndex {
            font-size: $FONTSIZE_28;
        }
    }
    .stepName {
        font-size: $FONTSIZE_24;
    }

    @include mobile {
        padding: 16px 18px;
        .stepIndex {
            font-size: $FONTSIZE_14;
            .currStepIndex {
                font-size: $FONTSIZE_20;
            }
        }
        .stepName {
            font-size: $FONTSIZE_16;
        }
    }
}
