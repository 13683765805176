@import '@styles/styles.scss';

.resultedWrapperTwoSimple {
    .amountInfosWrapper {
        display: flex;

        .infosTitle {
            display: none;
        }

        .infosWrapper {
            margin: auto;
        }
    }
}
