@import '@styles/styles.scss';

.btnCopyWrapper {
    .btn.btnCopy {
        min-width: 26px;
        height: 26px;
        .txtCopy {
            display: none;
        }
        &.MuiButton-text {
        }
    }
}
