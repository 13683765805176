@import '@styles/styles.scss';

.infosHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    position: relative;
    padding-bottom: 20px;

    @include mobile {
        padding-left: 10px;
        padding-right: 10px;
    }

    .headline {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;

        .title {
            margin-top: 20px;
            font-size: $FONTSIZE_40;
            text-align: center;
        }

        .iconHead {
            margin-right: 4px;
            width: 120px;
            height: 120px;

            @include tablet {
                width: 100px;
                height: 100px;
            }

            @include mobileSmall {
                width: 60px;
                height: 60px;
            }
        }

        .tickIco,
        .processIco,
        .cancelIco,
        .waitingIco {
            .fillArea {
                stroke: $primaryTextContrast;
            }

            #clock {
                fill: $primaryColor;
                stroke: $primaryColor;
            }
        }
    }

    .message {
        font-size: $FONTSIZE_18;
        margin: 0 30px;
        text-align: center;

        @include mobile {
            margin: 0 5px;
        }
    }

    @include tablet {
        padding-top: 50px;
        width: 100%;
    }
}
