@import '@styles/styles.scss';

button.btn.btnResend {
    border-radius: 0;
    border: none;
    .countDownSecondTxt,
    .text {
        color: $primaryTextContrast;
    }
}
