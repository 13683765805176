@import '@styles/styles.scss';

.recipientInfos {
    .infosWrapper {
        display: flex;
        align-items: flex-start;
        flex-direction: column;

        .leftInfos {
            display: flex;
            flex-direction: column;
            width: 100%;
            .leftAlphaInfosWrapper {
                margin-right: 20px;
            }
        }
    }

    .itemField.sellerName {
        .fieldValue {
            margin-bottom: 8px;
        }
        .fieldExtra {
            align-items: flex-start;
        }
        .itemTags {
            margin-left: 0;
            flex-wrap: wrap;

            .itemTag {
                margin-right: 9px;
            }
        }
    }

    .payMethodOptions {
        display: flex;

        .ItemPayMethodOption + .ItemPayMethodOption {
            margin-left: 5px;
        }
    }

    ol {
        margin-left: 15px;
    }
}
