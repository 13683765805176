@import '@styles/styles.scss';

.actionsDialogContent {
    .contentWrapper {
        margin-bottom: 30px;

        .headline {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .mainContent {
            margin-top: 25px;
        }
    }

    .dialogActionGroup {
        justify-content: center;
        flex-direction: row-reverse;
        margin-bottom: 40px;

        @include mobile {
            &.MuiDialogActions-root {
                .btn {
                    width: 90%;
                }
            }
        }
    }
}
